@font-face {
  font-family: 'InterTight';
  font-weight: 400;
  src: url('./fonts/InterTight/InterTight-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'InterTight';
  font-weight: 300;
  src: url('./fonts/InterTight/InterTight-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'InterTight';
  font-weight: 500;
  src: url('./fonts/InterTight/InterTight-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'InterTight';
  font-weight: 600;
  src: url('./fonts/InterTight/InterTight-VariableFont_wght.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: 'InterTight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
